<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        During your first time in the laboratory, what safety equipment should you walk around and
        find (check all 10 that apply)?
      </p>

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemOleMiss116SSQ0_Q11',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {text: 'First aid kit', value: 'aidKit'},
        {text: 'Spill kit', value: 'spillKit'},
        {text: 'Nearest fire extinguisher', value: 'extinguisher'},
        {text: 'Nearest fire alarm', value: 'fireAlarm'},
        {text: 'Exit routes for emergencies', value: 'exits'},
        {text: 'Nearest eye wash', value: 'eyeWash'},
        {text: 'Fire blanket', value: 'blanket'},
        {text: 'Safety shower', value: 'shower'},
        {text: 'Wifi passwords', value: 'wifi'},
        {text: 'Relevant emergency phone numbers', value: 'phoneNumbers'},
        {text: 'Projectors and screens', value: 'screens'},
        {text: 'ADA seating', value: 'seating'},
        {text: 'Distilled water dispenser', value: 'water'},
        {text: 'Broken-glass box', value: 'glassBox'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
